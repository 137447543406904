export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook("vuetify:configuration", vuetify => {
    vuetify.vuetifyOptions = vuetify.vuetifyOptions || {};
    vuetify.moduleOptions = vuetify.moduleOptions || { styles: { configFile: "./styles/variables.scss" } };
    Object.assign(vuetify.vuetifyOptions, {
      theme: {
        defaultTheme: "light",
        themes: {
          light: {
            dark: false,
            colors: {
              background: "#000", // for background color
              surface: "#043143", // for card color
              primary: "#A6BBFF",
              rating: "#f4c74d",
              button: "#3a96aa",
              breadcrumbs: "#FFFFFF",
              // cardTitle: "#dde7e9",
              linkColor: "#A6BBFF",
              jackpot: "#3a96aa",
              buttonTextColor: "#ffffff",
              buttonBackground: "#3a96aa",
              iconColor: "#3a96aa",
              toTop: "#0076a1",
            },
          },
          dark: {},
        },
      },
    });
  });
});
